define('lh-public-olo/components/item-modal/modifier-set', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var filter = Ember.computed.filter;
	var sort = Ember.computed.sort;
	var service = Ember.inject.service;
	var Component = Ember.Component;
	exports.default = Component.extend({
		classNames: ['modifier-set'],
		store: service(),
		didInsertElement: function didInsertElement() {
			// find modifier sets that are radio buttons (oneOnly && isRequired)
			// and create a default ticketItemModifier entry for the firstObject
			if (this.get('set.oneOnly') && this.get('set.isRequired') && !this.get('selected.firstObject')) {
				// use controller action to select modifier
				this.sendAction('modifierSelect', true, this.get('modifiers.firstObject'), this.get('set'));
			}
		},

		selected: filter('ticketItem.modifiers', function (mod) {
			return mod.get('modifierSetGuid') === this.get('set.id');
		}),
		modifiers: sort('set.modifiers', function (a, b) {
			var aMap = this.get('store').peekRecord('modifier-set-map', a.get('id') + this.get('set.id'));
			var bMap = this.get('store').peekRecord('modifier-set-map', b.get('id') + this.get('set.id'));
			if (aMap && bMap) {
				return aMap.get('ordinal') - bMap.get('ordinal');
			}
			return 0;
		}),
		actions: {
			modifierQuantityChange: function modifierQuantityChange(method, modifierGuid) {
				var modifier = this.get('selected').findBy('modifierGuid', modifierGuid);
				if (method === 'add' && modifier.get('quantity') < this.get('set.maxQuantityForIndividualModifier')) {
					modifier.incrementProperty('quantity');
				} else if (method === 'subtract' && modifier.get('quantity') > this.get('set.minQuantityForIndividualModifier')) {
					modifier.decrementProperty('quantity');
				}
			},
			modifierSelect: function modifierSelect(modifier, set, checked) {
				// handle case where oneOnly is true, but maxModifiersPerSet is > 1
				var maxModifiersPerSet = set.get('oneOnly') ? 1 : set.get('maxModifiersPerSet');

				if (this.get('selected.length') !== maxModifiersPerSet || !checked) {
					this.sendAction('modifierSelect', checked, modifier, set);
				} else {
					// I tried using the checked property to be more ember-y
					// but apparently that doesn't work the way I think it does
					this.$('#' + modifier.get('id') + set.get('id') + this.get('ticketItem.id')).checkbox('set unchecked');
				}
			},
			modifierRadioSelect: function modifierRadioSelect(modifier, set) {
				// remove old selected modifier in this set
				var oldRecord = this.get('selected.firstObject');
				this.get('ticketItem.modifiers').removeObject(oldRecord);
				oldRecord.unloadRecord();

				// use existing action to add new modifier
				this.sendAction('modifierSelect', true, modifier, set);
			}
		}
	});
});