define('lh-public-olo/models/item-modifier-set-map', ['exports', 'ember-data'], function (exports, _emberData) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var attr = _emberData.default.attr,
	    Model = _emberData.default.Model;
	exports.default = Model.extend({
		ordinal: attr('number')
	});
});