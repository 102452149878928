define('lh-public-olo/routes/location/checkout', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var service = Ember.inject.service;
	var Route = Ember.Route;
	var RSVP = Ember.RSVP;
	exports.default = Route.extend({
		ticket: service(),
		beforeModel: function beforeModel() {
			if (this.get('ticket.items.length') === 0) {
				this.transitionTo('location.menu');
			}
		},
		model: function model() {
			var _this = this;

			var location = this.modelFor('location');
			var promise = RSVP.Promise.resolve();

			// if an order type guid has been selected, then we should refresh the calculations
			if (this.get('ticket.orderTypeGuid') && this.get('ticket.items.length') !== 0) {
				promise = this.get('ticket').calculate();
			}

			return promise.then(function () {
				return RSVP.hash({
					location: location,
					customer: _this.store.peekAll('customer').get('firstObject') || _this.store.createRecord('customer', {
						address: _this.store.createRecord('customer-address')
					}),
					orderTypes: _this.store.query('order-type', { location: location.get('locationId') }),
					tenders: _this.store.query('tender', { location: location.get('locationId') }).then(function (tenders) {
						return {
							cash: tenders.findBy('name', 'Cash'),
							credit: tenders.findBy('name', 'Credit')
						};
					})
				});
			});
		},
		afterModel: function afterModel(model) {
			// set tenderTypeGuid to cash by default
			if (!this.get('ticket.tenderTypeGuid')) {
				this.set('ticket.tenderTypeGuid', model.tenders.credit.get('id'));
			}
		},
		actions: {
			willTransition: function willTransition() {
				// when the user leaves this route, clear the calculations
				// because if they edit their ticket, the calculations will be wrong
				return true;
			}
		}
	});
});