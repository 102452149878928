define('lh-public-olo/utils/has-local-storage', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = hasLocalStorage;
	/**
  * Determines if browser supports localStorage
  * Straight up stolen from modernizr
  *
  * @method     hasLocalStorage
  * @returns    {Boolean} whether or not the browser supports localStorage
  */
	function hasLocalStorage() {
		var mod = 'modernizr';
		try {
			localStorage.setItem(mod, mod);
			localStorage.removeItem(mod);
			return true;
		} catch (e) {
			return false;
		}
	}
});