define('lh-public-olo/components/order-details/ticket-item/modifier-set', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var filter = Ember.computed.filter;
	var sort = Ember.computed.sort;
	var service = Ember.inject.service;
	var Component = Ember.Component;
	exports.default = Component.extend({
		tagName: 'tbody',
		store: service(),
		filtered: filter('item.modifiers', function (mod) {
			return mod.get('modifierSetGuid') === this.get('set.id');
		}),
		modifiers: sort('filtered', function (a, b) {
			var aMap = this.get('store').peekRecord('modifier-set-map', a.get('id') + this.get('set.id'));
			var bMap = this.get('store').peekRecord('modifier-set-map', b.get('id') + this.get('set.id'));
			if (aMap && bMap) {
				return aMap.get('ordinal') - bMap.get('ordinal');
			}
			return 0;
		})
	});
});