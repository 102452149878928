define('lh-public-olo/models/department', ['exports', 'ember-data'], function (exports, _emberData) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var attr = _emberData.default.attr,
	    Model = _emberData.default.Model,
	    hasMany = _emberData.default.hasMany;
	var computed = Ember.computed;
	exports.default = Model.extend({
		name: attr('string'),
		onlineName: attr('string'),
		displayName: computed('name', 'onlineName', function () {
			return this.get('onlineName') || this.get('name');
		}),
		items: hasMany('item'),
		ordinal: attr('number')
	});
});