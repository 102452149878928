define('lh-public-olo/validators/credit-card', ['exports', 'ember-cp-validations/validators/base'], function (exports, _base) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var $ = Ember.$;


	var CreditCard = _base.default.extend({
		validate: function validate(value /*, options, model, attribute*/) {
			return $.payment.validateCardNumber(value);
		}
	});

	CreditCard.reopenClass({
		getDependentsFor: function getDependentsFor() /* attribute, options */{
			return [];
		}
	});

	exports.default = CreditCard;
});