define('lh-public-olo/models/ticket-item-modifier', ['exports', 'ember-data'], function (exports, _emberData) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var computed = Ember.computed;
	var Model = _emberData.default.Model,
	    attr = _emberData.default.attr,
	    belongsTo = _emberData.default.belongsTo;
	exports.default = Model.extend({
		ticketItemGuid: belongsTo('ticket-item'),
		modifierSetGuid: attr('string'),
		modifierGuid: attr('string'),
		quantity: attr('number'),
		modifierName: computed('modifier', function () {
			return this.get('modifier.onlineName') || this.get('modifier.name');
		}),
		value: computed('modifierSetMap', 'modifierSet', function () {
			// modifierSetMap only has the correct values when the set value is overridden
			// so if it's null, I need to get the values from the set
			if (this.get('modifierSetMap.onlineValue') || this.get('modifierSetMap.value')) {
				return parseFloat(this.get('modifierSetMap.onlineValue') || this.get('modifierSetMap.value')) || 0;
			}
			return parseFloat(this.get('modifierSet.value'));
		}),
		isFlatAmount: computed('modifierSetMap', 'modifierSet', function () {
			if (this.get('modifierSetMap.onlineValue') || this.get('modifierSetMap.value')) {
				return this.get('modifierSetMap.onlineValue') ? this.get('modifierSetMap.isFlatAmountOnline') : this.get('modifierSetMap.isFlatAmount');
			}
			return this.get('modifierSet.isFlatPrice');
		}),
		unitPrice: computed('value', 'isFlatAmount', function () {
			if (!this.get('isFlatAmount')) {
				return this.get('ticketItemGuid.unitPrice') * this.get('value');
			}
			return this.get('value');
		}),
		subtotal: computed('unitPrice', 'quantity', function () {
			return this.get('unitPrice') * this.get('quantity');
		}),
		// using these computed properties instead of relations because of some
		// technical decisions I made with regards to findBy's in templates
		modifier: computed('modifierGuid', function () {
			return this.store.peekRecord('modifier', this.get('modifierGuid'));
		}),
		modifierSet: computed('modifierSetGuid', 'ticketItemGuid.itemGuid.modifierSets.[]', function () {
			return this.store.peekRecord('modifier-set', this.get('modifierSetGuid'));
		}),
		modifierSetMap: computed('modifierSetGuid', 'modifierGuid', 'ticketItemGuid.itemGuid.modifierSets.[]', function () {
			return this.store.peekRecord('modifier-set-map', this.get('modifierGuid') + this.get('modifierSetGuid'));
		})
	});
});