define('lh-public-olo/helpers/usd', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.usd = usd;
	var helper = Ember.Helper.helper;
	function usd(params /*, hash*/) {
		return parseFloat(params[0]).toLocaleString('en-US', {
			style: 'currency',
			currency: 'USD'
		});
	}

	exports.default = helper(usd);
});