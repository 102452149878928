define('lh-public-olo/models/customer', ['exports', 'ember-data', 'ember-cp-validations'], function (exports, _emberData, _emberCpValidations) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var attr = _emberData.default.attr,
	    Model = _emberData.default.Model,
	    belongsTo = _emberData.default.belongsTo;


	var Validations = (0, _emberCpValidations.buildValidations)({
		firstName: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', { max: 255 })],
		lastName: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', { max: 255 })],
		email: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', { max: 255 }), (0, _emberCpValidations.validator)('format', { type: 'email' })],
		phone: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('phone-number')],
		address: (0, _emberCpValidations.validator)('belongs-to')
	});

	exports.default = Model.extend(Validations, {
		firstName: attr('string'),
		lastName: attr('string'),
		email: attr('string'),
		phone: attr('string'),
		address: belongsTo('customer-address', { async: false })
	});
});