define('lh-public-olo/validators/credit-card-expiration-date', ['exports', 'ember-cp-validations/validators/base'], function (exports, _base) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var $ = Ember.$;


	var CreditCardExpirationDate = _base.default.extend({
		validate: function validate(value) {
			var expiry = $.payment.cardExpiryVal(value);
			return $.payment.validateCardExpiry(expiry);
		}
	});

	CreditCardExpirationDate.reopenClass({
		getDependentsFor: function getDependentsFor() {
			return [];
		}
	});

	exports.default = CreditCardExpirationDate;
});