define('lh-public-olo/components/order-details/ticket-item', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var computed = Ember.computed;
	var sort = Ember.computed.sort;
	var service = Ember.inject.service;
	var Component = Ember.Component;
	exports.default = Component.extend({
		tagName: 'tbody',
		ticket: service(),
		store: service(),
		surcharges: computed('ticket.calculations', function () {
			if (this.get('ticket.calculations.items')) {
				var calcItem = this.get('ticket.calculations.items').findBy('guid', this.get('item.itemGuid.id'));
				return calcItem ? calcItem.surcharges.gross.fees.concat(calcItem.surcharges.net.fees) : undefined;
			}
			return undefined;
		}),
		modifierSets: sort('item.itemGuid.modifierSets', function (a, b) {
			var aMap = this.get('store').peekRecord('item-modifier-set-map', a.get('id') + this.get('item.itemGuid.id'));
			var bMap = this.get('store').peekRecord('item-modifier-set-map', b.get('id') + this.get('item.itemGuid.id'));
			if (aMap && bMap) {
				return aMap.get('ordinal') - bMap.get('ordinal');
			}
			return 0;
		}),
		actions: {
			removeItem: function removeItem() {
				this.sendAction.apply(this, ['removeItem'].concat(Array.prototype.slice.call(arguments)));
			}
		}
	});
});