define('lh-public-olo/components/order-type-modal', ['exports', 'lh-public-olo/helpers/pluralize', 'ember-cp-validations', 'lh-public-olo/utils/validation-check', 'lh-public-olo/utils/round-date'], function (exports, _pluralize, _emberCpValidations, _validationCheck, _roundDate) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var htmlSafe = Ember.String.htmlSafe;
	var computed = Ember.computed;
	var service = Ember.inject.service;
	var Component = Ember.Component;
	var not = Ember.computed.not;
	var RSVP = Ember.RSVP;


	var Validations = (0, _emberCpValidations.buildValidations)({
		orderTypeGuid: (0, _emberCpValidations.validator)('presence', true)
	});

	var AddressValidations = (0, _emberCpValidations.buildValidations)({
		'address.streetAddress1': (0, _emberCpValidations.validator)('presence', true),
		'address.city': (0, _emberCpValidations.validator)('presence', true),
		'address.state': (0, _emberCpValidations.validator)('presence', true),
		'address.postalCode': (0, _emberCpValidations.validator)('presence', true)
	}, {
		disabled: not('model.orderType.requiresAddress').volatile()
	});

	exports.default = Component.extend(Validations, AddressValidations, {
		ticket: service(),
		media: service(),
		didInsertElement: function didInsertElement() {
			this.$('.modal').modal('show');
			if (this.get('deferred') && new Date(this.get('dueDate')) < this.get('ticket.minDate')) {
				this.set('dueDate', (0, _roundDate.default)(this.get('ticket.minDate')));
			}
			if (this.get('orderTypeGuid')) {
				this.checkAvailability();
			}
		},
		willDestroyElement: function willDestroyElement() {
			this.$('.modal').modal('hide');
		},
		checkAvailability: function checkAvailability() {
			var _this = this;

			this.set('loading', true);
			this.get('ticket').checkAvailability(this.get('orderTypeGuid'), this.get('deferred'), this.get('dueDate')).then(function () {
				_this.set('availabilityError', undefined);
			}).catch(function (response) {
				_this.set('availabilityError', response.payload.error.details || response.payload.error.message);
			}).then(function () {
				_this.set('loading', false);
			});
		},
		states: ['AL', 'AK', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'DC', 'FL', 'GA', 'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MD', 'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ', 'NM', 'NY', 'NC', 'ND', 'OH', 'OK', 'OR', 'PA', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VT', 'VA', 'WA', 'WV', 'WI', 'WY'],
		orderType: computed('orderTypeGuid', function () {
			return this.get('orderTypes').findBy('id', this.get('orderTypeGuid'));
		}),
		laterLabel: computed('orderType', function () {
			var min = this.get('orderType.minDeferredTime');
			min = min ? (0, _pluralize.pluralize)([min, 'day']) : null;
			var max = this.get('orderType.maxDeferredTime');
			max = max ? (0, _pluralize.pluralize)([max, 'day']) : null;
			var label = 'Later ';
			if (min && max) {
				label += '<i>(min: ' + min + ', max: ' + max + ')</i>';
			} else if (min) {
				label += '<i>(min: ' + min + ')</i>';
			} else if (max) {
				label += '<i>(max: ' + max + ')</i>';
			}
			return htmlSafe(label);
		}),
		addLeadTime: function addLeadTime(minDate) {
			var leadTime = this.get('orderType.prepTimeMinutes');
			if (!leadTime) {
				return minDate;
			}

			return new Date(minDate.getTime() + leadTime * 60000);
		},

		deferred: false,
		dueDate: undefined,
		actions: {
			dueDateSelect: function dueDateSelect(date) {
				this.set('dueDate', date);
				if (this.get('orderTypeGuid')) {
					this.checkAvailability();
				}
			},
			deferredSelect: function deferredSelect(value) {
				this.set('deferred', value);
				if (value && this.get('dueDate') === undefined || new Date(this.get('dueDate')) < this.get('ticket.minDate')) {
					var dueDate = (0, _roundDate.default)(this.addLeadTime(this.get('ticket.minDate')));
					this.set('dueDate', dueDate);
				}

				if (this.get('orderTypeGuid')) {
					this.checkAvailability();
				}
			},
			orderTypeSelect: function orderTypeSelect(orderTypeGuid) {
				this.set('orderTypeGuid', orderTypeGuid);
				if (!(this.get('orderType.requiresAddress') && this.get('orderType.deliveryDistance'))) {
					this.set('rangeError', false);
				}
				if (this.get('orderType.isDeferred') === false) {
					this.send('deferredSelect', false);
				} else {
					this.checkAvailability();
				}
			},
			save: function save() {
				var _this2 = this;

				if (!this.get('loading') && !this.get('availabilityError')) {
					this.set('showErrors', false);
					this.set('rangeError', false);
					this.validate().then(_validationCheck.default).then(function () {
						if (_this2.get('orderType.requiresAddress')) {
							return _this2.get('address').validate().then(_validationCheck.default);
						}
						return RSVP.Promise.resolve();
					}).then(function () {
						if (!_this2.get('orderType.requiresAddress') || !_this2.get('orderType.deliveryDistance')) {
							return RSVP.Promise.resolve();
						}

						// if the order type requires address and has a delivery distance defined
						// validate that the address is within range of that distance
						_this2.set('loading', true);

						return _this2.get('ticket').validateDistance(_this2.get('address'), _this2.get('orderType')).then(function () {
							_this2.set('loading', false);
						}).catch(function (res) {
							_this2.set('loading', false);

							var message = res.payload && res.payload.error && res.payload.error.message ? 'Sorry, ' + res.payload.error.message + '.' : 'Sorry, an unexpected error occured.';
							_this2.set('rangeError', message);

							return RSVP.Promise.reject(res);
						});
					}).then(function () {
						_this2.get('ticket').setOrderSettings({
							orderTypeGuid: _this2.get('orderTypeGuid'),
							deferred: _this2.get('deferred'),
							dueDate: _this2.get('dueDate')
						});
						_this2.set('ticket.orderType', _this2.get('orderType'));
						_this2.get('ticket').calculate();
						_this2.sendAction('save');
					}).catch(function () {
						_this2.set('showErrors', true);
					});
				}
			}
		}
	});
});