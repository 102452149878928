define('lh-public-olo/utils/validation-check', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = validationCheck;
  var RSVP = Ember.RSVP;

  /**
   * Returns based on whether or not validations are valid
   *
   * @method    validationCheck
   * @param     {Object} validations ember-cp-validations object
   * @returns   {Object|RSVP.Promise} The validations if they're valid, otherwise a rejected Promise
   */
  function validationCheck(_ref) {
    var _RSVP$Promise;

    var validations = _ref.validations;

    if (validations.get('isValid')) {
      return validations;
    }
    return (_RSVP$Promise = RSVP.Promise).reject.apply(_RSVP$Promise, arguments);
  }
});