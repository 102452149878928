define('lh-public-olo/components/department-tabs', ['exports', 'lh-public-olo/mixins/header-child'], function (exports, _headerChild) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var schedule = Ember.run.schedule;
	var Component = Ember.Component;
	var $ = Ember.$;
	exports.default = Component.extend(_headerChild.default, {
		didInsertElement: function didInsertElement() {
			this.setActive(this.get('departments.firstObject'));
			this._super.apply(this, arguments);
		},
		didUpdateAttrs: function didUpdateAttrs() {
			// when showDropdown changes we want to make sure
			// the correct department is active
			schedule('afterRender', this, function () {
				this.setActive(this.get('department'), true);
			});
		},
		onScroll: function onScroll() {
			this.setActive();
		},
		/**
   * Get the active department based on scroll position
   *
   * @method     getActive
   * @returns    {Object} Model instance of the department currently active
   */
		getActive: function getActive() {
			// TODO: consider caching offsets for performance reasons
			var departments = this.get('departments').toArray();
			for (var x = 0; x < departments.length; x++) {
				var department = departments[x];
				var grid = $('#' + department.get('id') + ' + .ui.divider + .ui.grid')[0];
				// we're getting the bottom of the grid here
				// subtract 50px for padding reasons
				var offset = grid.offsetTop + grid.offsetHeight - 50;
				if ($('.menu.column').scrollTop() < offset) {
					return department;
				}
			}
		},
		/**
   * Set active state for specific department's tab
   *
   * @method     setActive
   * @param      {Object} department Model instance of the department to be considered as active
   * @param      {Boolean} force Whether or not to ignore department check
   */
		setActive: function setActive() {
			var department = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : this.getActive();
			var force = arguments[1];

			if (department !== this.get('department') || force) {
				// this whole function feels very anti "the ember way"
				// but running this inside of the runloop doesn't work
				var $tab = this.$('.item[data-value="' + department.get('id') + '"]');

				// scroll to tab if it's not in view
				if (!this.get('showDropdown')) {
					var $menu = this.$('.menu');
					var offsetLeft = $tab[0].offsetLeft + 14; // 14px padding
					var offsetRight = offsetLeft + $tab[0].offsetWidth;

					if (offsetLeft < $menu.scrollLeft()) {
						// tab is to the left of current scroll position
						$menu.stop().animate({ scrollLeft: offsetLeft }, 100);
					} else if (offsetRight > $menu.scrollLeft() + $menu.width()) {
						// tab is to the right of current scroll position
						$menu.stop().animate({ scrollLeft: offsetRight - $menu.width() }, 100);
					}
				}

				var activeClass = 'active';
				if (this.get('showDropdown')) {
					activeClass = 'active selected';
					this.$('.ui.dropdown').dropdown('set text', department.get('displayName'));
				}
				this.$('.item').removeClass(activeClass);
				$tab.addClass(activeClass);

				this.set('department', department);
			}
		},
		actions: {
			departmentSelect: function departmentSelect(guid) {
				var offset = document.getElementById(guid).offsetTop;
				// 14px is 1em for padding
				$('.menu.column').animate({ scrollTop: offset - 14 }, 350);
			}
		}
	});
});