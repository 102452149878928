define('lh-public-olo/models/modifier-set', ['exports', 'ember-data'], function (exports, _emberData) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var attr = _emberData.default.attr,
	    Model = _emberData.default.Model,
	    hasMany = _emberData.default.hasMany,
	    belongsTo = _emberData.default.belongsTo;
	exports.default = Model.extend({
		name: attr('string'),
		onlineDescription: attr('string'),
		onlineName: attr('string'),
		areModifierQuantitiesEnabled: attr('boolean'),
		oneOnly: attr('boolean'),
		minModifiersPerSet: attr('number'),
		maxModifiersPerSet: attr('number'),
		maxQuantityForIndividualModifier: attr('number'),
		minQuantityForIndividualModifier: attr('number'),
		isRequired: attr('boolean'),
		isFlatPrice: attr('boolean'),
		value: attr('number'),
		modifiers: hasMany('modifier'),
		ordinal: attr('number'),
		// pretty much ignore this embedded relation because it gets overwritten constantly
		// instead lookup the map you're looking for with modifierSetGuid + itemGuid
		itemModifierSetMap: belongsTo('item-modifier-set-map')
	});
});