define('lh-public-olo/components/order-details', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var computed = Ember.computed;
	var service = Ember.inject.service;
	var Component = Ember.Component;
	exports.default = Component.extend({
		ticket: service(),
		months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
		dueDateDisplay: computed('ticket.dueDate', function () {
			// warning: code might actually make your eyes bleed
			var dueDate = new Date(this.get('ticket.dueDate'));
			var minutes = dueDate.getMinutes();
			minutes = (minutes < 10 ? '0' : '') + minutes;
			var hours = dueDate.getHours();
			var meridiem = hours >= 12 ? 'PM' : 'AM';
			hours = hours > 12 ? hours - 12 : hours;
			hours = hours === 0 ? 12 : hours;
			hours = (hours < 10 ? '0' : '') + hours;

			return this.get('months')[dueDate.getMonth()] + ' ' + dueDate.getDate() + ', ' + dueDate.getFullYear() + ' ' + hours + ':' + minutes + meridiem;
		}),
		discountInfo: computed('ticket.calculations.{totals.discountTotal,activeCouponCode,activeCouponDiscount.totalAmount}', function () {
			var discountTotal = this.get('ticket.calculations.totals.discountTotal') || 0;
			var couponDiscountTotal = this.get('ticket.calculations.activeCouponDiscount.totalAmount') || 0;
			if (this.get('ticket.calculations.activeCouponCode')) {
				discountTotal -= couponDiscountTotal;
			}
			return { discountTotal: discountTotal, couponDiscountTotal: couponDiscountTotal };
		}),
		actions: {
			removeItem: function removeItem(item) {
				item.get('specialRequests').toArray().forEach(function (req) {
					return req.unloadRecord();
				});
				item.get('modifiers').toArray().forEach(function (mod) {
					return mod.unloadRecord();
				});
				item.destroyRecord();
				this.get('ticket.items').removeObject(item);
				if (this.get('ticket.orderTypeGuid')) {
					this.get('ticket').calculate();
				}
				this.sendAction('removeItem');
			}
		}
	});
});