define('lh-public-olo/routes/location', ['exports', 'lh-public-olo/utils/get-local-storage'], function (exports, _getLocalStorage2) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var service = Ember.inject.service;
	var Route = Ember.Route;
	var $ = Ember.$;
	var RSVP = Ember.RSVP;
	exports.default = Route.extend({
		ticket: service(),
		beforeModel: function beforeModel() {
			this.store.unloadAll();
			this.get('ticket').setProperties({
				locationId: undefined,
				tenderTypeGuid: undefined,
				orderTypeGuid: undefined
			});
			this.set('ticket.items', []);
		},
		model: function model(params) {
			return this.store.findRecord('location', params.channel);
		},
		afterModel: function afterModel(model) {
			var _this = this;

			if (model.get('stoUrl')) {
				// this is a bit of a nasty hack, it returns a never resolving promise
				// to make sure that ember doesn't try to start loading the menu's model
				// while dns resolving the redirect, and cause an error page to flash in Safari
				// refs: LH-26702
				return new RSVP.Promise(function () {
					window.location.replace(model.get('stoUrl'));
				});
			}

			model.get('services')['online-ordering'] = false;

			document.title = model.get('name') + ' - Online Ordering';

			this.set('ticket.locationId', model.get('locationId'));
			this.set('ticket.timezoneOffset', model.get('timezoneOffset'));

			// get ticket-items out of localStorage
			return this.store.query('ticket-item', { location: model.get('locationId') }).then(function (ticketItems) {
				_this.set('ticket.items', ticketItems.toArray());

				// get orderSettings from localStorage

				var _getLocalStorage = (0, _getLocalStorage2.default)(model.get('locationId')),
				    _getLocalStorage$orde = _getLocalStorage.orderSettings,
				    orderTypeGuid = _getLocalStorage$orde.orderTypeGuid,
				    deferred = _getLocalStorage$orde.deferred,
				    dueDate = _getLocalStorage$orde.dueDate,
				    discountCode = _getLocalStorage$orde.discountCode;

				if (deferred !== undefined) _this.set('ticket.deferred', deferred);
				if (dueDate !== undefined) _this.set('ticket.dueDate', dueDate);
				if (discountCode !== undefined) _this.set('ticket.discountCode', discountCode);
				if (orderTypeGuid !== undefined) {
					_this.set('ticket.orderTypeGuid', orderTypeGuid);
					return _this.store.findRecord('order-type', orderTypeGuid).then(function (orderType) {
						_this.set('ticket.orderType', orderType);
						_this.get('ticket').calculate();
					});
				}
			});
		},
		actions: {
			willTransition: function willTransition(transition) {
				switch (transition.targetName) {
					case 'location.checkout':
					case 'location.checkout.index':
					case 'location.menu':
					case 'location.menu.index':
						var $menuColumn = $('.menu.column');
						if ($menuColumn.dimmer('has dimmer')) {
							$menuColumn.dimmer('hide');
							$('.angle.icon').removeClass('vertically horizontally flipped');
							$('.order.column').transition('reset').css({
								top: '',
								height: ''
							});
							$('main').css('overflow', '');
						}
				}
				return true;
			}
		}
	});
});