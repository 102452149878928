define('lh-public-olo/models/customer-address', ['exports', 'ember-data', 'ember-cp-validations'], function (exports, _emberData, _emberCpValidations) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var attr = _emberData.default.attr,
	    Model = _emberData.default.Model,
	    belongsTo = _emberData.default.belongsTo;


	var Validations = (0, _emberCpValidations.buildValidations)({
		streetAddress1: (0, _emberCpValidations.validator)('length', { max: 255 }),
		streetAddress2: (0, _emberCpValidations.validator)('length', { max: 255 }),
		city: (0, _emberCpValidations.validator)('length', { max: 255 }),
		postalCode: (0, _emberCpValidations.validator)('format', {
			regex: /^\d{5}(-\d{4})?$/,
			allowBlank: true
		})
	});

	exports.default = Model.extend(Validations, {
		customerGuid: belongsTo('customer'),
		streetAddress1: attr('string'),
		streetAddress2: attr('string'),
		city: attr('string'),
		state: attr('string'),
		postalCode: attr('string')
	});
});