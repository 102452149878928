define('lh-public-olo/components/order-summary', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var observer = Ember.observer;
	var computed = Ember.computed;
	var service = Ember.inject.service;
	var Component = Ember.Component;
	var $ = Ember.$;
	exports.default = Component.extend({
		classNames: ['order-summary-component'],
		ticket: service(),
		media: service(),
		// REEEEE OBSERVERS REEEEE
		screenChange: observer('media.{isTablet,isMobile,isDesktop}', function () {
			// when the screen size changes, just hide everything
			var $menuColumn = $('.menu.column');
			if ($menuColumn.dimmer('has dimmer')) {
				$menuColumn.dimmer('hide');
				this.$('.angle.icon').removeClass('vertically horizontally flipped');
				$('.order.column').transition('reset').css({
					top: '',
					height: ''
				});
				$('main').css('overflow', '');
			}
		}),
		click: function click() {
			var transition = void 0;
			var icon = void 0;
			var iconClass = void 0;
			var $orderColumn = void 0;
			if (this.get('media.isTablet')) {
				transition = 'fly left';
				icon = '.angle.left.icon';
				iconClass = 'horizontally flipped';
			} else if (this.get('media.isMobile')) {
				transition = 'slide down';
				icon = '.angle.down.icon';
				iconClass = 'vertically flipped';

				var $main = $('main');
				var $headerColumn = $('header > .column:nth-child(2)');
				$orderColumn = $('.order.column');
				$orderColumn.css({
					top: -$headerColumn.prop('scrollHeight') - 14,
					height: $main.outerHeight() + $headerColumn.outerHeight()
				});
				$main.css('overflow', 'visible');
			}
			if (transition && icon && iconClass) {
				$('.menu.column').dimmer({ closable: false }).dimmer('toggle');
				this.$(icon).toggleClass(iconClass);
				$orderColumn = $orderColumn || $('.order.column');
				$orderColumn.transition(transition);
			}
		},
		numberOfItems: computed('ticket.items.[]', 'ticket.items.@each.quantity', function () {
			return this.get('ticket.items').reduce(function (a, b) {
				return a + b.get('quantity');
			}, 0);
		})
	});
});