define('lh-public-olo/adapters/ticket-item', ['exports', 'lh-public-olo/adapters/application', 'lh-public-olo/utils/get-local-storage', 'lh-public-olo/utils/set-local-storage'], function (exports, _application, _getLocalStorage2, _setLocalStorage) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var RSVP = Ember.RSVP;
	exports.default = _application.default.extend({
		query: function query(store, type, _query) {
			return new RSVP.Promise(function (resolve) {
				var _getLocalStorage = (0, _getLocalStorage2.default)(_query.location),
				    items = _getLocalStorage.items;

				return resolve({ ticketItems: items });
			});
		},
		createRecord: function createRecord(store, type, snapshot) {
			var _this = this;

			return new RSVP.Promise(function (resolve) {
				var data = _this.serialize(snapshot, { includeId: true });
				var storage = (0, _getLocalStorage2.default)(data.locationId);

				storage.items.push(data);

				(0, _setLocalStorage.default)(data.locationId, storage);
				return resolve();
			});
		},
		updateRecord: function updateRecord(store, type, snapshot) {
			var _this2 = this;

			return new RSVP.Promise(function (resolve) {
				var data = _this2.serialize(snapshot, { includeId: true });
				var storage = (0, _getLocalStorage2.default)(data.locationId);

				// ie11 doesn't support findIndex :(
				var pos = storage.items.indexOf(storage.items.find(function (item) {
					return item.guid === data.guid;
				}));
				storage.items[pos] = data;

				(0, _setLocalStorage.default)(data.locationId, storage);
				return resolve();
			});
		},
		deleteRecord: function deleteRecord(store, type, snapshot) {
			var _this3 = this;

			return new RSVP.Promise(function (resolve) {
				var data = _this3.serialize(snapshot, { includeId: true });
				var storage = (0, _getLocalStorage2.default)(data.locationId);

				// remove item from localStorage
				var pos = storage.items.indexOf(storage.items.find(function (item) {
					return item.guid === data.guid;
				}));
				storage.items.splice(pos, 1);

				(0, _setLocalStorage.default)(data.locationId, storage);
				return resolve();
			});
		}
	});
});