define('lh-public-olo/helpers/to-fixed', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.toFixed = toFixed;
	var helper = Ember.Helper.helper;
	function toFixed(params /*, hash*/) {
		return Number(params[0]).toFixed(params[1]);
	}

	exports.default = helper(toFixed);
});