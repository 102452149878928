define('lh-public-olo/routes/location/confirmation', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var service = Ember.inject.service;
	var Route = Ember.Route;
	exports.default = Route.extend({
		ticket: service(),
		beforeModel: function beforeModel() {
			if (!this.get('ticket.submitResponse')) {
				this.transitionTo('location.menu');
			}
		},
		model: function model() {
			return this.get('ticket.submitResponse');
		},
		actions: {
			willTransition: function willTransition() {
				// when the user leaves this route, clear the calculations
				this.set('ticket.calculations', undefined);
			}
		}
	});
});