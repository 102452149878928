define('lh-public-olo/components/full-header', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var Component = Ember.Component;
	exports.default = Component.extend({
		tagName: '',
		/* @param */
		location: null
	});
});