define('lh-public-olo/helpers/mult', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.mult = mult;
	var helper = Ember.Helper.helper;


	// stole this from ember-math-helpers
	function mult(params /*, hash*/) {
		return params.reduce(function (a, b) {
			return Number(a) * Number(b);
		});
	}

	exports.default = helper(mult);
});