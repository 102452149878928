define('lh-public-olo/utils/set-local-storage', ['exports', 'lh-public-olo/utils/get-local-storage', 'lh-public-olo/utils/has-local-storage'], function (exports, _getLocalStorage, _hasLocalStorage) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = setLocalStorage;
	var isEmpty = Ember.isEmpty;

	/**
  * Sets data in localStorage for locations
  *
  * @method     setLocalStorage
  * @param      {Number} locationId id for location related to data
  * @param      {Object[]} items array of ticket-items
  * @param      {String} orderTypeGuid id for order type
  * @param      {Boolean} deferred whether or not the order is deferred
  * @param      {Date} dueDate date/time that the order is due
  */
	function setLocalStorage(locationId, _ref) {
		var items = _ref.items,
		    _ref$orderSettings = _ref.orderSettings,
		    orderTypeGuid = _ref$orderSettings.orderTypeGuid,
		    deferred = _ref$orderSettings.deferred,
		    dueDate = _ref$orderSettings.dueDate,
		    discountCode = _ref$orderSettings.discountCode;

		if ((0, _hasLocalStorage.default)()) {
			// if something wasn't passed in, grab the old info out of localStorage
			// useful for things like setting orderTypeGuid where you're not pulling localStorage originally
			if (items === undefined || orderTypeGuid === undefined || deferred === undefined || dueDate === undefined || discountCode === undefined) {
				var data = (0, _getLocalStorage.default)(locationId);
				items = items || data.items;

				orderTypeGuid = orderTypeGuid || data.orderSettings.orderTypeGuid;
				deferred = deferred !== undefined ? deferred : data.orderSettings.deferred;
				dueDate = deferred ? dueDate || data.orderSettings.dueDate : undefined;
				discountCode = discountCode !== undefined ? discountCode : data.orderSettings.discountCode;
			}

			if (dueDate instanceof Date) {
				dueDate = dueDate.toString();
			}

			if (!isEmpty(items) || orderTypeGuid !== undefined || deferred !== undefined || dueDate !== undefined || discountCode !== undefined) {
				localStorage.setItem(locationId, JSON.stringify({ items: items, orderSettings: { orderTypeGuid: orderTypeGuid, deferred: deferred, dueDate: dueDate, discountCode: discountCode }, expiresAt: Date.now() + 7.2e6 }));
			} else {
				localStorage.removeItem(locationId);
			}
		}
	}
});