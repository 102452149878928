define('lh-public-olo/routes/location/menu/order-settings', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var Route = Ember.Route;
	var RSVP = Ember.RSVP;
	exports.default = Route.extend({
		model: function model() {
			var location = this.modelFor('location');
			return RSVP.hash({
				orderTypes: this.store.query('order-type', { location: location.get('locationId') }),
				customer: this.store.peekAll('customer').get('firstObject') || this.store.createRecord('customer', {
					address: this.store.createRecord('customer-address')
				})
			});
		}
	});
});