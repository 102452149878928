define('lh-public-olo/serializers/modifier-set-map', ['exports', 'lh-public-olo/serializers/application'], function (exports, _application) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _application.default.extend({
		normalize: function normalize(model, hash) {
			// combine the two ids so that it has a unique pk
			hash.guid = hash.modifierGuid + hash.modifierSetGuid;
			return this._super.apply(this, arguments);
		}
	});
});