define('lh-public-olo/services/ticket', ['exports', 'lh-public-olo/config/environment', 'lh-public-olo/helpers/usd', 'ember-cp-validations', 'lh-public-olo/utils/set-local-storage', 'lh-public-olo/utils/iso-date-format'], function (exports, _environment, _usd, _emberCpValidations, _setLocalStorage, _isoDateFormat) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	function _defineProperty(obj, key, value) {
		if (key in obj) {
			Object.defineProperty(obj, key, {
				value: value,
				enumerable: true,
				configurable: true,
				writable: true
			});
		} else {
			obj[key] = value;
		}

		return obj;
	}

	var Service = Ember.Service;
	var service = Ember.inject.service;
	var computed = Ember.computed;
	var RSVP = Ember.RSVP;


	var Validations = (0, _emberCpValidations.buildValidations)({
		orderTypeGuid: (0, _emberCpValidations.validator)('presence', true),
		tenderTypeGuid: (0, _emberCpValidations.validator)('presence', true),
		selectedTipCustom: {
			validators: [(0, _emberCpValidations.validator)('number', {
				positive: true,
				allowString: true
			}),
			// gross regex way to make sure the number isn't in scientific notation
			// because keith hates rich people
			(0, _emberCpValidations.validator)('format', {
				regex: /^\d*(\.\d+)?$/
			})],
			description: 'Custom tip',
			disabled: computed('model.paymentTipTypeName', 'model.selectedTip', function () {
				return !(this.get('model.paymentTipTypeName') === 'credit-card' && this.get('model.selectedTip') === 'custom');
			})
		}
	});

	exports.default = Service.extend(Validations, {
		locationId: undefined,
		deferred: false,
		dueDate: undefined,
		minDate: computed(function () {
			return this.newDateAdjusted();
		}).volatile(),
		orderTypeGuid: undefined,
		orderType: undefined,
		tenderTypeGuid: undefined,
		paymentTipTypeName: 'credit-card',
		discountCode: undefined,

		total: computed('subtotal', 'calculations', function () {
			return this.get('calculations.totals.grandTotal') || this.get('subtotal');
		}),

		amountRangeViolation: computed('orderType', 'subtotal', 'calculations', function () {
			var minOrderAmount = this.get('orderType.minOrderAmount') || undefined;
			var maxOrderAmount = this.get('orderType.maxOrderAmount') || undefined;
			if (this.get('total') < minOrderAmount) {
				return 'We\u2019re sorry, but your order does not meet the minimum amount of ' + (0, _usd.usd)([minOrderAmount]) + ' for your selected order type.';
			} else if (this.get('total') > maxOrderAmount) {
				return 'We\u2019re sorry, but your order surpasses the maximum amount of ' + (0, _usd.usd)([maxOrderAmount]) + ' for your selected order type.';
			}
		}),

		discountCodeApplied: computed('discountCodeValid', 'calculations.activeCouponCode', 'discountCodeLoading', function () {
			return this.get('discountCodeValid') && this.get('calculations.activeCouponCode') && !this.get('discountCodeLoading');
		}),
		discountCodeNotApplicable: computed('discountCodeValid', 'calculations.activeCouponCode', 'discountCodeLoading', function () {
			return this.get('discountCodeValid') && !this.get('calculations.activeCouponCode') && !this.get('discountCodeLoading');
		}),

		// for setting all order settings at once
		setOrderSettings: function setOrderSettings(_ref) {
			var orderTypeGuid = _ref.orderTypeGuid,
			    deferred = _ref.deferred,
			    dueDate = _ref.dueDate;

			if (orderTypeGuid && deferred && dueDate) {
				this.setProperties({ orderTypeGuid: orderTypeGuid, deferred: deferred, dueDate: dueDate });
				(0, _setLocalStorage.default)(this.get('locationId'), { orderSettings: { orderTypeGuid: orderTypeGuid, deferred: deferred, dueDate: dueDate } });
			} else if (orderTypeGuid && deferred === false) {
				this.setProperties({ orderTypeGuid: orderTypeGuid, deferred: deferred });
				(0, _setLocalStorage.default)(this.get('locationId'), { orderSettings: { orderTypeGuid: orderTypeGuid, deferred: deferred } });
			}
		},
		// for setting one singular order setting
		setOrderSetting: function setOrderSetting(key, value) {
			this.set(key, value);
			(0, _setLocalStorage.default)(this.get('locationId'), { orderSettings: _defineProperty({}, key, value) });
		},

		newDateAdjusted: function newDateAdjusted() {
			var now = new Date();
			var ourOffset = now.getTimezoneOffset();
			var theirOffset = this.get('timezoneOffset') || 0;
			var millisecondsInAMinute = 60000;
			return new Date(now - (theirOffset - ourOffset) * millisecondsInAMinute);
		},

		items: [],
		subtotal: computed('items.@each.subtotal', function () {
			return this.get('items').reduce(function (prev, curr) {
				return prev + curr.get('subtotal');
			}, 0);
		}),

		ajax: service(),
		calculate: function calculate() {
			var _this = this;

			if (this.get('items.length') !== 0 && this.get('orderTypeGuid')) {
				this.set('calculateLoading', true);
				// get ticket calculations from backend
				return this.get('ajax').post(_environment.default.host + '/api/v1/public/online-ordering/calculate', {
					data: {
						locationId: this.get('locationId'),
						orderTypeGuid: this.get('orderTypeGuid'),
						items: this.get('items').map(function (item) {
							return item.serialize({ includeId: true });
						}),
						activeCouponCode: this.get('discountCode')
					}
				}).then(function (response) {
					_this.set('calculateLoading', false);
					_this.set('calculateError', undefined);
					_this.set('calculations', response);
					return response;
				}).catch(function (e) {
					_this.set('calculateLoading', false);
					_this.set('calculateError', e.payload.error);
					_this.set('calculations', undefined);
				});
			}
			this.set('calculations', undefined);
			return RSVP.Promise.resolve();
		},
		checkAvailability: function checkAvailability() {
			var orderTypeGuid = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : this.get('orderTypeGuid');
			var deferred = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : this.get('deferred');
			var dueDate = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : this.get('dueDate');

			return this.get('ajax').request(_environment.default.host + '/api/v1/public/online-ordering/availability', {
				data: {
					orderTypeGuid: orderTypeGuid,
					location: this.get('locationId'),
					requestedTime: (0, _isoDateFormat.default)(deferred ? dueDate : this.newDateAdjusted()),
					isDeferred: deferred ? true : undefined
				}
			});
		},
		validateDistance: function validateDistance(address, orderType) {
			return this.get('ajax').post(_environment.default.host + '/api/v1/public/online-ordering/validate-distance', {
				data: {
					locationId: this.get('locationId'),
					orderTypeGuid: orderType.get('id'),
					addressLine1: address.get('streetAddress1'),
					city: address.get('city'),
					state: address.get('state'),
					zip: address.get('postalCode')
				}
			}).then(function (response) {
				if (!response.result) {
					var distance = orderType.get('deliveryDistance');
					var message = 'this address is outside of the maximum delivery distance of ' + distance + ' miles';

					return RSVP.Promise.reject({ payload: { error: { message: message } } });
				}
			});
		},
		_validateDiscountCode: function _validateDiscountCode() {
			var couponCode = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : this.get('discountCode');
			var locationId = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : this.get('locationId');

			return this.get('ajax').post(_environment.default.host + '/api/v1/public/online-ordering/validate-coupon', {
				data: { couponCode: couponCode, locationId: locationId }
			});
		},
		validateDiscountCode: function validateDiscountCode() {
			var _this2 = this;

			var discountCode = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : this.get('discountCode');

			this.set('discountCodeValid', false);
			this.set('discountCodeError', undefined);
			this.set('discountCodeLoading', true);
			if (discountCode) {
				return this._validateDiscountCode(discountCode).catch(function (response) {
					_this2.setOrderSetting('discountCode', null);
					_this2.set('discountCodeError', response.payload.error.details || response.payload.error.message);
					return RSVP.Promise.reject();
				}).then(function () {
					_this2.setOrderSetting('discountCode', discountCode);
					_this2.set('discountCodeValid', true);
				}).finally(function () {
					return _this2.calculate();
				}).finally(function () {
					_this2.set('discountCodeLoading', false);
					return RSVP.Promise.resolve();
				});
			}
			this.setOrderSetting('discountCode', null);
			return this.calculate().finally(function () {
				_this2.set('discountCodeLoading', false);
				return RSVP.Promise.resolve();
			});
		}
	});
});