define('lh-public-olo/components/order-checkout', ['exports', 'lh-public-olo/config/environment', 'lh-public-olo/helpers/pluralize', 'ember-cp-validations', 'lh-public-olo/utils/validation-check', 'lh-public-olo/utils/iso-date-format', 'lh-public-olo/utils/round-date'], function (exports, _environment, _pluralize, _emberCpValidations, _validationCheck, _isoDateFormat, _roundDate) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var htmlSafe = Ember.String.htmlSafe;
	var service = Ember.inject.service;
	var Component = Ember.Component;
	var not = Ember.computed.not;
	var alias = Ember.computed.alias;
	var computed = Ember.computed;
	var RSVP = Ember.RSVP;


	var CardValidations = (0, _emberCpValidations.buildValidations)({
		cardNumber: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('credit-card')],
		expirationDate: {
			validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('credit-card-expiration-date')]
		},
		nameOnCard: (0, _emberCpValidations.validator)('presence', true),
		cvv: (0, _emberCpValidations.validator)('presence', true)
	}, {
		// made this CP volatile, because otherwise it wasn't recomputing
		// even with valid dependent keys
		disabled: computed('model.ticket.tenderTypeGuid', function () {
			return this.get('model.tenders.credit.id') !== this.get('model.ticket.tenderTypeGuid');
		}).volatile()
	});

	var AddressValidations = (0, _emberCpValidations.buildValidations)({
		'address.streetAddress1': (0, _emberCpValidations.validator)('presence', true),
		'address.city': (0, _emberCpValidations.validator)('presence', true),
		'address.state': (0, _emberCpValidations.validator)('presence', true),
		'address.postalCode': (0, _emberCpValidations.validator)('presence', true)
	}, {
		disabled: not('model.orderType.requiresAddress').volatile()
	});

	exports.default = Component.extend(CardValidations, AddressValidations, {
		ticket: service(),
		ajax: service(),
		states: ['AL', 'AK', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'DC', 'FL', 'GA', 'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MD', 'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ', 'NM', 'NY', 'NC', 'ND', 'OH', 'OK', 'OR', 'PA', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VT', 'VA', 'WA', 'WV', 'WI', 'WY'],
		tipAmounts: [0.15, 0.18, 0.20],
		didInsertElement: function didInsertElement() {
			if (this.get('ticket.deferred') && new Date(this.get('ticket.dueDate')) < this.get('ticket.minDate')) {
				this.get('ticket').setOrderSetting('dueDate', (0, _roundDate.default)(this.get('ticket.minDate')));
			}
			if (this.get('ticket.orderTypeGuid')) {
				this.checkAvailability();
			}
			if (this.get('ticket.discountCode')) {
				this.set('discountCode', this.get('ticket.discountCode'));
				this.send('applyDiscount');
			}
		},
		checkAvailability: function checkAvailability() {
			var _this = this;

			this.set('loading', true);
			return this.get('ticket').checkAvailability().then(function () {
				_this.set('loading', false);
				_this.set('availabilityError', undefined);
			}).catch(function (response) {
				_this.set('loading', false);
				_this.set('availabilityError', response.payload.error.details || response.payload.error.message);
				return RSVP.Promise.reject();
			});
		},
		address: alias('customer.address'),
		orderType: computed('ticket.orderTypeGuid', function () {
			return this.get('orderTypes').findBy('id', this.get('ticket.orderTypeGuid'));
		}),
		laterLabel: computed('orderType', function () {
			var min = this.get('orderType.minDeferredTime');
			min = min ? (0, _pluralize.pluralize)([min, 'day']) : null;
			var max = this.get('orderType.maxDeferredTime');
			max = max ? (0, _pluralize.pluralize)([max, 'day']) : null;
			var label = 'Later ';
			if (min && max) {
				label += '<i>(min: ' + min + ', max: ' + max + ')</i>';
			} else if (min) {
				label += '<i>(min: ' + min + ')</i>';
			} else if (max) {
				label += '<i>(max: ' + max + ')</i>';
			}
			return htmlSafe(label);
		}),
		showErrors: false,
		actions: {
			dueDateSelect: function dueDateSelect(date) {
				this.get('ticket').setOrderSetting('dueDate', date);
				if (this.get('ticket.orderTypeGuid')) {
					this.checkAvailability();
				}
			},
			deferredSelect: function deferredSelect(value) {
				this.get('ticket').setOrderSetting('deferred', value);
				if (value && this.get('ticket.dueDate') === undefined || new Date(this.get('ticket.dueDate')) < this.get('ticket.minDate')) {
					this.get('ticket').setOrderSetting('dueDate', (0, _roundDate.default)(this.get('ticket.minDate')));
				} else if (value) {
					this.get('ticket').setOrderSetting('dueDate', this.get('ticket.dueDate'));
				}
				if (this.get('ticket.orderTypeGuid')) {
					this.checkAvailability();
				}
			},
			orderTypeSelect: function orderTypeSelect(orderTypeGuid) {
				this.get('ticket').setOrderSetting('orderTypeGuid', orderTypeGuid);
				this.set('ticket.orderType', this.get('orderType'));
				this.get('ticket').calculate();
				if (!(this.get('orderType.requiresAddress') && this.get('orderType.deliveryDistance'))) {
					this.set('rangeError', false);
				}
				if (this.get('orderType.isDeferred') === false) {
					this.send('deferredSelect', false);
				} else {
					this.checkAvailability();
				}
			},
			clearDiscount: function clearDiscount() {
				this.set('discountCode', null);
				this.send('applyDiscount');
			},
			applyDiscount: function applyDiscount() {
				if (this.get('discountCode')) {
					this.set('discountCode', this.get('discountCode').toUpperCase());
				}
				this.get('ticket').validateDiscountCode(this.get('discountCode'));
			},
			tenderTypeSelect: function tenderTypeSelect(tenderTypeGuid) {
				this.set('ticket.tenderTypeGuid', tenderTypeGuid);
				if (tenderTypeGuid === this.get('tenders.cash.id') && this.get('ticket.paymentTipTypeName') === 'credit-card') {
					if (this.get('tenders.cash.isTipAllowed')) {
						this.send('tipSelect', 'cash');
					} else {
						this.send('tipSelect', null);
					}
				}
			},
			tipSelect: function tipSelect(tipType) {
				this.set('ticket.paymentTipTypeName', tipType);
				if (tipType === null || tipType === 'cash') {
					this.set('ticket.selectedTip', undefined);
				}
			},
			customTipSelect: function customTipSelect() {
				this.set('ticket.selectedTip', 'custom');
				this.$('.ui.basic.label > .ui.input > input').focus();
			},
			submitOrder: function submitOrder() {
				var _this2 = this;

				if (!this.get('loading') && !this.get('availabilityError') && !this.get('ticket.amountRangeViolation') && !this.get('ticket.calculateError') && this.get('captchaResponse')) {
					this.set('showErrors', false);
					this.set('rangeError', false);
					this.get('customer').validate().then(_validationCheck.default).then(function () {
						return _this2.get('ticket').validate();
					}).then(_validationCheck.default).then(function () {
						return _this2.validate();
					}).then(_validationCheck.default).then(function () {
						return _this2.checkAvailability();
					}).then(function () {
						if (!_this2.get('orderType.requiresAddress') || !_this2.get('orderType.deliveryDistance')) {
							return RSVP.Promise.resolve();
						}

						// if the order type requires address and has a delivery distance defined
						// validate that the address is within range of that distance
						_this2.set('loading', true);

						return _this2.get('ticket').validateDistance(_this2.get('address'), _this2.get('orderType')).catch(function (res) {
							_this2.set('loading', false);

							var message = res.payload && res.payload.error && res.payload.error.message ? 'Sorry, ' + res.payload.error.message + '.' : 'Sorry, an unexpected error occured.';
							_this2.set('rangeError', message);

							return RSVP.Promise.reject(res);
						});
					}).then(function () {
						var expirationDate = _this2.get('expirationDate') || '';

						// remove empty spaces and forward slashes from expiraiton date
						expirationDate = expirationDate.replace(/\s/g, '').replace(/\//g, '');

						// Safari autofill does not add leading 0 to the month number
						if (expirationDate.length === 3 || expirationDate.length === 5) {
							expirationDate = '0' + expirationDate;
						}

						// BE expects expiration date to follow MMYY format
						// some browsers autofill with MM/YYYY format which causes BE to reject
						if (expirationDate.length === 6) {
							expirationDate = expirationDate.slice(0, 2) + expirationDate.slice(-2);
						}

						_this2.set('loading', true);
						return _this2.get('ajax').raw(_environment.default.paymentGateway + '/api/v1/payments/', {
							type: 'POST',
							data: {
								locationId: _this2.get('location.locationId'),
								orderTypeGuid: _this2.get('ticket.orderTypeGuid'),
								tenderGuid: _this2.get('ticket.tenderTypeGuid'),
								tenderName: _this2.get('ticket.tenderTypeGuid') === _this2.get('tenders.cash.id') ? 'Cash' : 'Credit',
								items: _this2.get('ticket.items').map(function (item) {
									return item.serialize({ includeId: true });
								}),
								grandTotal: _this2.get('ticket.calculations.totals.grandTotal'),
								payment: {
									tipAmount: _this2.get('ticket.selectedTip') === 'custom' ? _this2.get('ticket.selectedTipCustom') : _this2.get('ticket.selectedTip'),
									cardNumber: _this2.get('cardNumber'),
									expirationDate: expirationDate,
									nameOnCard: _this2.get('nameOnCard'),
									cvv: _this2.get('cvv')
								},
								dueDate: _this2.get('ticket.deferred') ? (0, _isoDateFormat.default)(_this2.get('ticket.dueDate')) : undefined,
								customer: _this2.get('customer').serialize(),
								specialRequest: _this2.get('specialRequest'),
								activeCouponCode: _this2.get('ticket.discountCode'),
								captchaResponse: _this2.get('captchaResponse')
							}
						});
					}).then(function (_ref) {
						var response = _ref.response;

						_this2.set('ticket.submitResponse', response);
						_this2.sendAction('submitResponse');
					}).catch(function (e) {
						if (e && e.validations) {
							_this2.set('showErrors', true);
						} else if (_this2.get('rangeError') || _this2.get('availabilityError')) {
							return;
						} else if (e && e.payload) {
							_this2.set('ticket.submitResponse', e.payload);
							_this2.sendAction('submitResponse');
						} else {
							_this2.set('ticket.submitResponse', { status: 'error' });
							_this2.sendAction('submitResponse');
						}
					});
				}
			}
		}
	});
});