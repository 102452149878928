define('lh-public-olo/utils/iso-date-format', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = isoDateFormat;
	/**
  * Formats js Date into an ISO-8601 format of YYYY-MM-DDTHH:mm:ss
  *
  * @method     isoDateFormat
  * @param      {Date} date Date to format
  * @returns    {string} Formatted date
  */
	function isoDateFormat(date) {
		if (!(date instanceof Date)) date = new Date(date);
		var pad = function pad(num) {
			return (num < 10 ? '0' : '') + num;
		};
		var YYYY = date.getFullYear();
		var MM = pad(date.getMonth() + 1);
		var DD = pad(date.getDate());
		var HH = pad(date.getHours());
		var mm = pad(date.getMinutes());
		var ss = pad(date.getSeconds());
		return YYYY + '-' + MM + '-' + DD + 'T' + HH + ':' + mm + ':' + ss;
	}
});