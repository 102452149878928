define('lh-public-olo/routes/location/menu/edit-item', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var Route = Ember.Route;
	var RSVP = Ember.RSVP;
	exports.default = Route.extend({
		model: function model(params) {
			var ticketItem = this.store.peekRecord('ticket-item', params.ticketItemId);
			if (!ticketItem) {
				return this.transitionTo('location');
			}
			return RSVP.hash({
				item: this.store.findRecord('item', ticketItem.get('itemGuid.id'), { reload: true }),
				ticketItem: ticketItem,
				specialRequest: ticketItem.get('specialRequests.firstObject')
			});
		},
		setupController: function setupController(controller, model) {
			this._super.apply(this, arguments);
			controller.set('initialRecord', model.ticketItem.serialize({ includeId: true }));
		}
	});
});