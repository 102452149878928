define('lh-public-olo/helpers/keys', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.keys = keys;
	var helper = Ember.Helper.helper;
	function keys(params /*, hash*/) {
		return Object.keys(params[0]);
	}

	exports.default = helper(keys);
});