define('lh-public-olo/router', ['exports', 'lh-public-olo/config/environment'], function (exports, _environment) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var EmberRouter = Ember.Router;
	var service = Ember.inject.service;


	var Router = EmberRouter.extend({
		location: _environment.default.locationType,
		rootURL: _environment.default.rootURL
	});

	if (_environment.default.environment !== 'test') {
		Router.reopen({
			metrics: service(),
			didTransition: function didTransition() {
				this._super.apply(this, arguments);

				var page = this.get('url');
				var title = this.getWithDefault('currentRouteName', 'unknown');
				this.get('metrics').trackPage({ page: page, title: title });
			}
		});
	}

	Router.map(function () {
		this.route('location', { path: ':channel/*wildcard' });
		this.route('location', { path: ':channel' });
	});

	exports.default = Router;
});