define('lh-public-olo/models/modifier', ['exports', 'ember-data'], function (exports, _emberData) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var attr = _emberData.default.attr,
	    Model = _emberData.default.Model,
	    belongsTo = _emberData.default.belongsTo;
	exports.default = Model.extend({
		name: attr('string'),
		onlineName: attr('string'),
		onlineDescription: attr('string'),
		// pretty much ignore this embedded relation because it gets overwritten constantly
		// instead lookup the map you're looking for with modifierGuid + modifierSetGuid
		modifierSetMap: belongsTo('modifier-set-map'),
		ordinal: attr('number')
	});
});