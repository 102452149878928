define('lh-public-olo/models/ticket-item', ['exports', 'ember-data'], function (exports, _emberData) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var computed = Ember.computed;
	var Model = _emberData.default.Model,
	    attr = _emberData.default.attr,
	    hasMany = _emberData.default.hasMany,
	    belongsTo = _emberData.default.belongsTo;
	exports.default = Model.extend({
		locationId: attr('number'),
		itemGuid: belongsTo('item'),
		quantity: attr('number', { defaultValue: 1 }),
		itemName: computed('itemGuid.onlineName', 'itemGuid.name', function () {
			return this.get('itemGuid.onlineName') || this.get('itemGuid.name');
		}),
		unitPrice: computed('itemGuid.onlinePrice', 'itemGuid.price', function () {
			return this.get('itemGuid.onlinePrice') || this.get('itemGuid.price');
		}),
		subtotal: computed('unitPrice', 'quantity', 'modifierTotal', function () {
			return (parseFloat(this.get('unitPrice')) + this.get('modifierTotal')) * this.get('quantity');
		}),
		modifierTotal: computed('modifiers.[]', 'modifiers.@each.subtotal', function () {
			return this.get('modifiers').reduce(function (prev, curr) {
				return prev + curr.get('subtotal');
			}, 0);
		}),
		modifiers: hasMany('ticket-item-modifier'),
		specialRequests: hasMany('ticket-special-request')
	});
});