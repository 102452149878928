define('lh-public-olo/routes/location/menu/add-item', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var service = Ember.inject.service;
	var Route = Ember.Route;
	var RSVP = Ember.RSVP;
	exports.default = Route.extend({
		ticket: service(),
		model: function model(params) {
			var _this = this;

			if (!this.get('ticket.orderTypeGuid')) {
				this.replaceWith('location.menu.order-settings', { queryParams: { previous: this.routeName, id: params.guid } });
			}

			return this.store.findRecord('item', params.guid, { reload: true }).then(function (item) {
				var hash = {
					item: item,
					ticketItem: _this.store.createRecord('ticket-item', {
						locationId: _this.get('ticket.locationId'),
						itemGuid: item,
						quantity: item.get('defaultQty'),
						modifiers: [],
						specialRequests: []
					})
				};
				return RSVP.hash(hash);
			});
		},
		actions: {
			willTransition: function willTransition() {
				var model = this.modelFor(this.routeName);
				if (!this.get('ticket.items').includes(model.ticketItem)) {
					model.ticketItem.get('modifiers').toArray().forEach(function (ticketItemModifier) {
						return ticketItemModifier.unloadRecord();
					});
					var specialRequest = model.ticketItem.get('specialRequests.firstObject');
					if (specialRequest) {
						specialRequest.unloadRecord();
					}
					model.ticketItem.unloadRecord();
				}
			}
		}
	});
});