define('lh-public-olo/components/error-popup', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var Component = Ember.Component;
	var $ = Ember.$;
	exports.default = Component.extend({
		classNames: ['ui', 'error', 'popup'],
		showOnInsert: true,
		didInsertElement: function didInsertElement() {
			var popup = $(this.get('popupTarget')).popup({
				position: this.get('position') || 'top right',
				popup: this.$(),
				on: 'click'
			});

			if (this.get('showOnInsert')) {
				popup.popup('show');
			}
		}
	});
});