define('lh-public-olo/validators/phone-number', ['exports', 'ember-cp-validations/validators/base', 'libphonenumber'], function (exports, _base, _libphonenumber) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	var phoneUtil = _libphonenumber.default.PhoneNumberUtil.getInstance();

	var PhoneNumber = _base.default.extend({
		validate: function validate(value /*, options, model, attribute*/) {
			try {
				return phoneUtil.isValidNumber(phoneUtil.parse(value, 'US'));
			} catch (e) {
				return false;
			}
		}
	});

	PhoneNumber.reopenClass({
		getDependentsFor: function getDependentsFor() /* attribute, options */{
			return [];
		}
	});

	exports.default = PhoneNumber;
});