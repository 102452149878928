define('lh-public-olo/components/menu-column', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var service = Ember.inject.service;
	var Component = Ember.Component;
	exports.default = Component.extend({
		ticket: service()
	});
});