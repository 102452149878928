define('lh-public-olo/components/ui-calendar', ['exports', 'semantic-ui-ember/mixins/base'], function (exports, _base) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var Component = Ember.Component;
	exports.default = Component.extend(_base.default, {
		module: 'calendar',
		classNames: ['ui', 'calendar']
	});
});