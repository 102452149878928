define('lh-public-olo/models/order-type', ['exports', 'ember-data'], function (exports, _emberData) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var attr = _emberData.default.attr,
	    Model = _emberData.default.Model;
	exports.default = Model.extend({
		name: attr('string'),
		onlineName: attr('string'),
		minOrderAmount: attr('number'),
		maxOrderAmount: attr('number'),
		deliveryDistance: attr('number'),
		requiresAddress: attr('boolean'),
		isDeferred: attr('boolean'),
		minDeferredTime: attr('number'),
		maxDeferredTime: attr('number'),
		prepTimeMinutes: attr('number')
	});
});