define('lh-public-olo/serializers/ticket-item', ['exports', 'ember-data', 'lh-public-olo/serializers/application'], function (exports, _emberData, _application) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var EmbeddedRecordsMixin = _emberData.default.EmbeddedRecordsMixin;
	exports.default = _application.default.extend(EmbeddedRecordsMixin, {
		attrs: {
			modifiers: { embedded: 'always' },
			specialRequests: { embedded: 'always' }
		}
	});
});