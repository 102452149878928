define('lh-public-olo/app', ['exports', 'lh-public-olo/resolver', 'ember-load-initializers', 'lh-public-olo/config/environment'], function (exports, _resolver, _emberLoadInitializers, _environment) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var Application = Ember.Application;


	var App = Application.extend({
		modulePrefix: _environment.default.modulePrefix,
		podModulePrefix: _environment.default.podModulePrefix,
		Resolver: _resolver.default
	});

	(0, _emberLoadInitializers.default)(App, _environment.default.modulePrefix);

	exports.default = App;
});