define('lh-public-olo/routes/location/menu', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var service = Ember.inject.service;
	var Route = Ember.Route;
	var RSVP = Ember.RSVP;
	exports.default = Route.extend({
		ticket: service(),
		model: function model() {
			var location = this.modelFor('location');
			return RSVP.hash({
				departments: this.store.query('department', { location: location.get('locationId') }),
				items: this.store.query('item', { location: location.get('locationId') })
			}).then(function (result) {
				return RSVP.hash({
					location: location,
					// filter out departments without any items
					departments: result.departments.filter(function (department) {
						return department.get('items.length') !== 0;
					}).sortBy('ordinal', 'name')
				});
			});
		}
	});
});