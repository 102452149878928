define('lh-public-olo/controllers/location/confirmation', ['exports', 'lh-public-olo/config/environment'], function (exports, _environment) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var service = Ember.inject.service;
	var Controller = Ember.Controller;
	exports.default = Controller.extend({
		ticket: service(),
		ENV: _environment.default
	});
});