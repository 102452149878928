define('lh-public-olo/controllers/location/menu/order-settings', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var service = Ember.inject.service;
	var Controller = Ember.Controller;
	exports.default = Controller.extend({
		ticket: service(),
		queryParams: ['previous', 'id'],
		previous: 'location.menu',
		actions: {
			save: function save() {
				if (this.get('id')) {
					this.replaceRoute(this.get('previous'), this.get('id'));
				} else {
					this.replaceRoute(this.get('previous'));
				}
			}
		}
	});
});