define('lh-public-olo/components/credit-card-form', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var $ = Ember.$;
	var computed = Ember.computed;
	var service = Ember.inject.service;
	var Component = Ember.Component;
	exports.default = Component.extend({
		ticket: service(),
		classNames: ['ui', 'form', 'checkout', 'credit-card'],
		didInsertElement: function didInsertElement() {
			this.$('input.cc-num').payment('formatCardNumber');
			this.$('input.cc-cvv').payment('formatCardCVC');
			this.$('input.cc-exp').payment('formatCardExpiry');
		},
		supportedCardTypeIcons: ['amex', 'dinersclub', 'discover', 'jcb', 'mastercard', 'visa'],
		cardType: computed('cardNumber', function () {
			var cardType = $.payment.cardType(this.get('cardNumber'));
			if (this.get('supportedCardTypeIcons').includes(cardType)) {
				return cardType === 'dinersclub' ? 'diners club' : cardType;
			}
			return null;
		})
	});
});