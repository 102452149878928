define('lh-public-olo/mixins/item-selection', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var service = Ember.inject.service;
	var Mixin = Ember.Mixin;
	exports.default = Mixin.create({
		ticket: service(),
		actions: {
			modifierSelect: function modifierSelect(checked, modifier, set) {
				if (checked) {
					var record = this.store.createRecord('ticket-item-modifier', {
						ticketItemGuid: this.get('model.ticketItem.id'),
						modifierSetGuid: set.get('id'),
						modifierGuid: modifier.get('id'),
						quantity: set.get('areModifierQuantitiesEnabled') ? set.get('minQuantityForIndividualModifier') : 1
					});
					this.get('model.ticketItem.modifiers').pushObject(record);
				} else {
					var _record = this.get('model.ticketItem.modifiers').find(function (item) {
						return item.get('modifierSetGuid') === set.get('id') && item.get('modifierGuid') === modifier.get('id');
					});
					this.get('model.ticketItem.modifiers').removeObject(_record);
					_record.unloadRecord();
				}
			},
			specialRequests: function specialRequests(value) {
				var record = this.get('model.ticketItem.specialRequests.firstObject');
				if (value) {
					if (record) {
						record.set('request', value);
					} else {
						this.set('model.ticketItem.specialRequests', [this.store.createRecord('ticket-special-request', {
							ticketItemGuid: this.get('model.ticketItem.id'),
							request: value
						})]);
					}
				} else {
					if (record) {
						record.unloadRecord();
					}
				}
			},
			addToOrder: function addToOrder() {
				this.get('ticket').calculate();
				this.transitionToRoute('location');
			},
			cancelOrder: function cancelOrder() {
				this.transitionToRoute('location');
			}
		}
	});
});