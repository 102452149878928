define('lh-public-olo/helpers/html-safe', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.htmlSafe = htmlSafe;
	var helper = Ember.Helper.helper;
	var htmlSafeString = Ember.String.htmlSafe;
	function htmlSafe(params /*, hash*/) {
		return htmlSafeString(params[0]);
	}

	exports.default = helper(htmlSafe);
});