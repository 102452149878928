define('lh-public-olo/mixins/header-child', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var bind = Ember.run.bind;
	var service = Ember.inject.service;
	var Mixin = Ember.Mixin;
	var $ = Ember.$;
	exports.default = Mixin.create({
		media: service(),
		didInsertElement: function didInsertElement() {
			var _this = this;

			// check for passive scroll listener support
			var supportsPassive = false;
			try {
				var opts = Object.defineProperty({}, 'passive', {
					get: function get() {
						supportsPassive = true;
					}
				});
				window.addEventListener('test', null, opts);
			} catch (e) {} /* ¯\_(ツ)_/¯ */

			// watch scroll to dynamically hide header when mobile
			// this listener doesn't work well inside the runloop
			var menuColumn = document.querySelector('.menu.column');
			if (menuColumn) {
				menuColumn.addEventListener('scroll', function () {
					if (_this.get('media.isMobile')) {
						_this.headerHide();
					}
					// optional function that can be used by the component to also include scrolling functionality
					_this.onScroll();
				}, supportsPassive ? { passive: true } : false);
			}
			// to remove this listener later on I need to have a reference to the callback
			// I'm setting it here to bind this to the mixin/component and not to window
			// using Ember.run.bind means that this callback executes within the runloop
			this.set('_onResize', bind(this, function () {
				if (this.get('media.isMobile')) {
					this.headerHide();
				} else {
					this.headerReset();
				}
			}));
			// when the window changes sizes make sure the header still makes sense
			window.addEventListener('resize', this._onResize);
		},
		willDestroyElement: function willDestroyElement() {
			this.headerReset();

			// remove resize listener so it doesn't leak to other routes
			window.removeEventListener('resize', this._onResize);
		},
		onScroll: function onScroll() {},
		headerReset: function headerReset() {
			var lh = $('.location-header');
			var inner = lh.children('.inner');

			lh.height('auto');
			inner.css({ top: 0 });
		},
		headerHide: function headerHide() {
			var lh = $('.location-header');
			var inner = lh.children('.inner');
			var header = inner.children('.ui.header');

			var headerHeight = header.outerHeight(true);
			var st = $('.menu.column').scrollTop();
			st = st < headerHeight ? st : headerHeight;

			lh.height(inner.outerHeight(true) - st);
			inner.css({ top: -st });
		}
	});
});