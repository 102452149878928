define('lh-public-olo/components/item-modal', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var service = Ember.inject.service;
	var sort = Ember.computed.sort;
	var computed = Ember.computed;
	var Component = Ember.Component;
	exports.default = Component.extend({
		didInsertElement: function didInsertElement() {
			this.$('.modal').modal('show');
		},
		willDestroyElement: function willDestroyElement() {
			this.$('.modal').modal('hide');
		},
		store: service(),
		specialInstructions: '',
		showErrors: false,
		modifierSets: sort('item.modifierSets', function (a, b) {
			var aMap = this.get('store').peekRecord('item-modifier-set-map', a.get('id') + this.get('item.id'));
			var bMap = this.get('store').peekRecord('item-modifier-set-map', b.get('id') + this.get('item.id'));
			if (aMap && bMap) {
				return aMap.get('ordinal') - bMap.get('ordinal');
			}
			return 0;
		}),
		errors: computed('ticketItem.modifiers', 'ticketItem.modifiers.@each.quantity', function () {
			var _this = this;

			var errors = {};
			this.get('item.modifierSets').forEach(function (set) {
				var group = _this.get('ticketItem.modifiers').filterBy('modifierSetGuid', set.get('id'));
				var total = group.reduce(function (quantity, mod) {
					return quantity + mod.get('quantity');
				}, 0);
				if (total > set.get('maxModifiersPerSet') && !set.get('oneOnly') || total < set.get('minModifiersPerSet') && set.get('isRequired')) {
					errors[set.get('id')] = true;
				}
			});
			return errors;
		}),
		actions: {
			quantityChange: function quantityChange(method) {
				if (method === 'add' && this.get('ticketItem.quantity') < 99) {
					this.get('ticketItem').incrementProperty('quantity');
				} else if (method === 'subtract' && this.get('ticketItem.quantity') > 1) {
					this.get('ticketItem').decrementProperty('quantity');
				}
			},
			modifierSelect: function modifierSelect() {
				// passthrough action
				this.sendAction.apply(this, ['modifierSelect'].concat(Array.prototype.slice.call(arguments)));

				// using notifyPropertyChange allows me to control when the CPs are recomputed
				this.notifyPropertyChange('ticketItem.modifiers');
			},
			addToOrder: function addToOrder() {
				var _this2 = this;

				// make sure there are no errors before doing anything
				if (Object.keys(this.get('errors')).length === 0) {
					this.get('ticketItem').save().then(function () {
						_this2.sendAction('addToOrder');
					});
				} else {
					this.set('showErrors', true);
				}
			},
			cancelOrder: function cancelOrder() {
				this.sendAction('cancelOrder');
			},
			specialRequests: function specialRequests() {
				this.sendAction('specialRequests', this.get('specialInstructions'));
			}
		}
	});
});