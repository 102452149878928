define('lh-public-olo/components/error-modal', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var Component = Ember.Component;
	exports.default = Component.extend({
		didInsertElement: function didInsertElement() {
			this.$('.modal').modal('show');
		},
		willDestroyElement: function willDestroyElement() {
			this.$('.modal').modal('hide');
		},
		actions: {
			reloadPage: function reloadPage() {
				window.location.reload();
			}
		}
	});
});