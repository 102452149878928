define('lh-public-olo/controllers/location/menu/add-item', ['exports', 'lh-public-olo/mixins/item-selection'], function (exports, _itemSelection) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var Controller = Ember.Controller;
	exports.default = Controller.extend(_itemSelection.default, {
		actions: {
			addToOrder: function addToOrder() {
				this.get('ticket.items').pushObject(this.get('model.ticketItem'));
				this._super.apply(this, arguments);
			}
		}
	});
});