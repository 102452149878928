define('lh-public-olo/controllers/location/checkout', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var service = Ember.inject.service;
	var Controller = Ember.Controller;
	exports.default = Controller.extend({
		ticket: service(),
		actions: {
			submitResponse: function submitResponse() {
				// if it was good, clear all the items out of localStorage
				if (this.get('ticket.submitResponse.status') === 'ok') {
					this.get('ticket.items').forEach(function (item) {
						return item.destroyRecord();
					});
					this.set('ticket.items', []);
					this.get('ticket').validateDiscountCode(null);
				}
				this.transitionToRoute('location.confirmation');
			},
			removeItem: function removeItem() {
				if (this.get('ticket.items.length') === 0) {
					this.transitionToRoute('location.menu');
				} else if (this.get('ticket.orderTypeGuid')) {
					this.get('ticket').calculate();
				}
			}
		}
	});
});