define('lh-public-olo/adapters/application', ['exports', 'ember-inflector', 'ember-data', 'lh-public-olo/config/environment', 'uuid'], function (exports, _emberInflector, _emberData, _environment, _uuid) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
		return typeof obj;
	} : function (obj) {
		return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
	};

	var dasherize = Ember.String.dasherize;
	exports.default = _emberData.default.RESTAdapter.extend({
		namespace: 'api/v1/public/online-ordering',
		host: _environment.default.host,
		pathForType: function pathForType(type) {
			return (0, _emberInflector.pluralize)(dasherize(type));
		},
		generateIdForRecord: function generateIdForRecord() {
			return (0, _uuid.v4)();
		},
		// this is private, whoops
		normalizeErrorResponse: function normalizeErrorResponse(status, headers, payload) {
			if (payload && (typeof payload === 'undefined' ? 'undefined' : _typeof(payload)) === 'object' && payload.error) {
				return [payload.error];
			}
			return this._super.apply(this, arguments);
		}
	});
});