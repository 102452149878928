define('lh-public-olo/controllers/location/menu/edit-item', ['exports', 'lh-public-olo/mixins/item-selection'], function (exports, _itemSelection) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var Controller = Ember.Controller;
	exports.default = Controller.extend(_itemSelection.default, {
		actions: {
			cancelOrder: function cancelOrder() {
				var ticketItem = this.get('model.ticketItem');
				var oldItem = this.get('initialRecord');

				ticketItem.rollbackAttributes();

				// unload all the records and then load in the copy we made at the beginning
				// allows for a way to rollback easily
				ticketItem.get('modifiers').toArray().forEach(function (mod) {
					return mod.unloadRecord();
				});
				ticketItem.get('specialRequests').toArray().forEach(function (req) {
					return req.unloadRecord();
				});
				this.store.pushPayload('ticket-item', { ticketItem: oldItem });

				this._super.apply(this, arguments);
			}
		}
	});
});