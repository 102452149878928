define('lh-public-olo/models/location', ['exports', 'ember-data'], function (exports, _emberData) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var attr = _emberData.default.attr,
	    Model = _emberData.default.Model;
	exports.default = Model.extend({
		locationId: attr('number'),
		name: attr('string'),
		phone: attr('string'),
		addressLine1: attr('string'),
		addressLine2: attr('string'),
		city: attr('string'),
		state: attr('string'),
		zip: attr('string'),
		country: attr('string'),
		services: attr(),
		timezoneOffset: attr('number'),
		brand: attr(),
		logoUrl: attr('string'),
		stoUrl: attr('string')
	});
});