define('lh-public-olo/models/item', ['exports', 'ember-data'], function (exports, _emberData) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var attr = _emberData.default.attr,
	    Model = _emberData.default.Model,
	    belongsTo = _emberData.default.belongsTo,
	    hasMany = _emberData.default.hasMany;
	exports.default = Model.extend({
		name: attr('string'),
		onlineName: attr('string'),
		price: attr('number'),
		onlinePrice: attr('number'),
		onlineDescription: attr('string'),
		defaultQty: attr('number'),
		departmentGuid: belongsTo('department'),
		modifierSets: hasMany('modifier-set'),
		imageUrl: attr('string')
	});
});